import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

function Footer() {
    return(
        <Container>
            <Row>
                <Col style={{padding: '3rem' }} className="text-center justify-content-center align-items-center d-flex">
                    <p style={{color: '#fefefe'}}>Beauty Hair Palermo. Sviluppato da <a href="https://realizzaonline.it/">Realizza Online</a></p>
                </Col>
            </Row>
        </Container>
    );
}
export default Footer;