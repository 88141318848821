import React, {useState, useEffect} from 'react';
import Table from 'react-bootstrap/esm/Table';
import Logo from '../Global/Logo';
import Footer from '../Footer/Footer';
import NavBar from '../Global/NavBar';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import { ArrowLeft, ArrowRight, CheckLg, CodeSlash } from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/esm/Modal';
import Form from 'react-bootstrap/esm/Form'
import { makeRequest } from '../Global/makeRequest';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import Schede from '../Schede/Schede';
import { uSocket } from '../Socket/Socket';
import Confirm from '../Global/Confirm';

function Calendario() {
    const [back, setBack] = useState(true);
    const [prensel, setPren] = useState([])
    const [tableid, setTableID] = useState(0);
    const [last, setLast] = useState([])
    const [show, setShow] = useState(false);
    const [schedaExist, setSchedaExist] = useState(false);
    const [confirm, showConfirm] = useState(false);
    const [festivi, setFestivi] = useState([])
    const [nextday, setNextDay] = useState([]);
    const [nexthour, setNextHour] = useState([]);
    const [disabledhour, setDisabledHour] = useState(false);
    const [today, setToday] = useState(null);
    const [datavalue, setDatavalue] = useState(null);
    const [showScheda, setShowScheda] = useState(false);
    const handleChangeDay = (e) => {
        const value = e.currentTarget.value
        createListDate(value)  
        setDatavalue(0);
        setPren({...prensel, Giorno: value})
    }
    const handleDelete = () => {
        if(window.confirm("Sei sicuro di voler eliminare questa prenotazione?")) {
            const id = prensel.ID;
            const nome = prensel.Nome;
            const cellulare = prensel.Cellulare;
            const to = prensel.Email;
            const data = prensel.Data;
            const ora = prensel.Ora;
            let subject = "Prenotazione Rifiutata"
            const text = "Ciao "+nome+", la tua prenotazione di "+getData(data)+" alle ore "+ora+" è stata rifiutata. Prenota un nuovo appuntamento su https://beautyhairpalermo.it/";
            const link = "whatsapp://send?phone=39"+cellulare+"&abid=39"+cellulare+'&text='+text;
            window.location.href = link;
            setTimeout(() => {
                window.location.href = link;
            }, 3000)
            makeRequest.post('/pren/remove/', {id})
            .then(res => {
                handleClose();
                setPrenotazioni([])
                makeRequest.post('/pren/sendpren',{to,subject,text,text});

            })
            .catch(err => console.log(err))
        }
    }
    const handleChangeDate = (e) => {
        const value = e.currentTarget.value
        setDatavalue(value);
        createListHour(dataymd(value), prensel.Operatore, prensel.Ora, false)
        setPren({...prensel, Data: value});
    }

    const getOperatore = (id) => {
        switch(id) {
            case 1: {
                return "Giada";
            }
            case 2: {
                return "Fabio";
            }
            case 3: {
                return "Cristina";
            }
            default: return "Errore";
        }
    }
    const handleBack = () => {
        if(tableid-1 === 0)
            setBack(true);
        setTableID(prev => prev-1);
    }
    const handleForward = () => {
        setBack(false);
        setTableID(prev => prev+1);
    }
    const handleClose = () => {
        setShow(false);
        setPren([]);
    }
    const handleAvvisa = () => {
        const text = "Ciao "+prensel.Nome+", Ti ricordiamo la tua prenotazione di "+getData(prensel.Data)+" alle ore "+prensel.Ora+".";
        const link = "whatsapp://send?phone=39"+prensel.Cellulare+"&abid=39"+prensel.Cellulare+'&text='+text;
        window.location.href = link;
        setTimeout(() => {
            window.location.href = link;
        }, 3000)
    }
    const dataymd = (data) => {
        const split = data.split('-');
        return split[2]+'-'+split[1]+'-'+split[0];
    }
    const createListHour = async(data, operatore, ora, defaultVal) => {
        let arrStandard = [];
        let arrOperatore = [];
        let arrOccupate = [];
        let arrOperDisabled = []
        let arrFinale = [];
        await makeRequest.post('/pren/standard/', {operatore})
        .then(res => arrStandard = res.data)
        .catch(err => console.log(err))
        await makeRequest.post('/pren/operatore/', {operatore, data})
        .then(res => arrOperatore = res.data)
        .catch(err => console.log(err))
        await makeRequest.post('/pren/prenoperatore/', {operatore, data})
        .then(res => arrOccupate = res.data)
        .catch(err => console.log(err))
        await makeRequest.post('/pren/operdisabled/', {operatore, data})
        .then(res => arrOperDisabled = res.data)
        .catch(err => console.log(err))
        data = dataymd(data);
        let entro = false;
        arrFinale = arrStandard.map((standard) => {
            for(var i = 0; i < arrOperDisabled.length; i++) {
                if(arrOperDisabled[i].Data === data) {
                    if(arrOperDisabled[i].RifOperatore === operatore) {
                        if(arrOperDisabled[i].Attivo == 0) {
                            entro = true;
                            return {...standard, Attivo: 0}
                        }
                    }
                }
                //return false;
            }
            if(standard.Attivo === 0) {
                for(var i = 0; i < arrOperatore.length; i++) {
                    if(arrOperatore[i].Ora === standard.RifOra) {
                        if(arrOperatore[i].Attivo == 1) {
                            for(var b = 0; b < arrOccupate.length; b++) {
                                if(arrOccupate[b].Data === arrOperatore[i].Data) {
                                    if(defaultVal) {
                                        if(arrOccupate[b].Data === data) {
                                            if(arrOccupate[b].Ora == ora) {
                                                if(arrOccupate[b].Operatore === operatore) {
                                                    return {...standard, Attivo: 1}
                                                }
                                            }
                                        }
                                    }
                                    if(arrOccupate[b].Ora === arrOperatore[i].Ora) {
                                        return {...standard, Attivo: 0}
                                    } else {
                                        return {...standard, Attivo: 1}
                                    }
                                } else {
                                    return {...standard, Attivo: 1}
                                }
                            }
                        }
                        return {...standard, Attivo: 1}
                    }
                }
            } else if(standard.Attivo === 1) {
                for(var i = 0; i < arrOperatore.length; i++) {
                    if(arrOperatore[i].Ora === standard.RifOra) {
                        if(arrOperatore[i].Attivo == 0) {
                            return {...standard, Attivo: 0}
                        }
                    }
                }
                for(var i = 0; i < arrOccupate.length; i++) {
                    if(defaultVal) {
                        if(standard.RifOra === arrOccupate[i].Ora) {
                            if(arrOccupate[i].Data === data) {
                                if(arrOccupate[i].Ora === ora) {
                                    if(arrOccupate[i].Operatore === operatore) {
                                        return {...standard, Attivo: 1}
                                    }
                                }
                            }
                        }
                    }
                    if(arrOccupate[i].Ora === standard.RifOra) {
                        return {...standard, Attivo: 0}
                    }
                }
            }
            return standard;
        });
        if(entro)
            setDisabledHour(true);
        else
            setDisabledHour(false);
        setNextHour(arrFinale);
        return arrFinale;
    }
    const checkFestivi = (data) => {
        let trovato = false;
        festivi.map((item) => {
            if(item.Festivo === data)
                return trovato = true;
            return false;
        })
        return trovato;
    }
    const createListDate = (value) => {
        const dataoggi = new Date();
        let i  = 0;
        const arrDate = [];
        const numofDays = ((7 - dataoggi.getDay()) % 7 + 1*value) % 7;
        const newDate = dataoggi.getDate() + numofDays;
        dataoggi.setDate(newDate);
        while(i < 10) {
            if(i > 0)
                dataoggi.setDate(dataoggi.getDate()+7);
            const currentYear = dataoggi.getFullYear();
            const currentMonth = dataoggi.getMonth()+1;
            const day = dataoggi.getDate();
            const datafinale = (day < 10 ? '0'+day : day)+'-'+(currentMonth < 10 ? '0'+currentMonth : currentMonth)+'-'+currentYear;
            if(!checkFestivi(datafinale)) {
                arrDate.push(datafinale)
            } else {
                if(i === 0)
                    dataoggi.setDate(dataoggi.getDate()+7);
                i--;
            }
            i++;
        }

        setNextDay(arrDate);
        return arrDate;
    }
    const handlePren = (e) => {
        const id = e.currentTarget.id;
        const arr = prenotazioni.filter((item) => item.ID == id)
        setPren(arr[0]);
        createListDate(arr[0].Giorno);
        createListHour(dataymd(arr[0].Data), arr[0].Operatore, arr[0].Ora, true);
        setDatavalue(arr[0].Data);
        const nome = arr[0].Nome;
        const cellulare = arr[0].Cellulare;
        makeRequest.post('/schede/checkscheda',{nome, cellulare})
        .then(res=> setSchedaExist(true))
        .catch(err=> setSchedaExist(false))
        setShow(true);
    }
    const handleApertura = (arr) => {
        let newarr = []
        if(arr?.length) {
            newarr = arr?.map((item) => {
                for(let i = 0; i < festivi?.length; i++) {
                    if(item.data == festivi[i].Festivo) {
                        return {...item, open: false}
                    } else if(item.data == festivi[i].Aperta) {
                        return {...item, open: true}
                    }
                }
                return item;
            })
        }
        return newarr;
    }
    const checkEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(email) ) {
            return true;
        }
        else {
            return false;
        }
    }
    function validateName(name) {
        var isValidName = true;
        if(/[!@#$%^&*(),.?":{}|<>]/g.test(name) ||/* !/^[A-Z]/.test(name) ||*/ /\d+/g.test(name)) {
          isValidName = false;
        }
        return isValidName;
    }
    const handleCrea = () => {
        const newscheda = {Nome: prensel.Nome, Cognome: prensel.Cognome, Cellulare: prensel.Cellulare, Scheda: ''};
        makeRequest.post('/schede/new', {newscheda})
        .then(res=> {          
            showConfirm(true);
            setSchedaExist(true);
            setTimeout(() => {
                showConfirm(false);
            },3000)
        })
        .catch(err => console.log(err))
    }
    const handleSave = () => {
        if(prensel.Nome.length >= 2 && validateName(prensel.Nome)) {
            if(prensel.Cognome.length >=2 && validateName(prensel.Cognome)) {
                if(String(prensel.Cellulare).length === 10 && !isNaN(parseInt(prensel.Cellulare))) {
                    if(checkEmail(prensel.Email)) {
                        if(prensel.Servizi.length >= 3) {
                            if(prensel.Ora.length) {
                                if(datavalue !== 0) {
                                    makeRequest.put('/pren/update',{prensel})
                                    .then(res => {
                                        let to = prensel.Email;
                                        let subject = "Prenotazione Modificata"
                                        const text = "Ciao "+prensel.Nome+", la tua prenotazione è stata modificata. Ti aspetto "+getData(prensel.Data)+" alle ore "+prensel.Ora;
                                        const link = "whatsapp://send?phone=39"+prensel.Cellulare+"&abid=39"+prensel.Cellulare+'&text='+text;
                                        window.location.href = link;
                                        setTimeout(() => {
                                            window.location.href = link;
                                        }, 3000)
                                        /*makeRequest.post('/pren/sendmail/',{to, subject, text, text})*/
                                        setPrenotazioni([]);
                                        handleClose();
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        //mostra errore
                                    })
                                }
                            }
                        }
                    }
                }
            }
        }
    }    
    const getMese = (mese) => {
        let val = '';
        switch(mese) {
            case '01': {
                val = 'Gennaio';
                break;
            }
            case '02': {
                val = 'Febbraio';
                break;
            }
            case '03': {
                val = 'Marzo';
                break;
            }
            case '04': {
                val = 'Aprile';
                break;
            }
            case '05': {
                val = 'Maggio';
                break;
            }
            case '06': {
                val = 'Giugno';
                break;
            }
            case '07': {
                val = 'Luglio';
                break;
            }
            case '08': {
                val = 'Agosto';
                break;
            }
            case '09': {
                val = 'Settembre';
                break;
            }
            case '10': {
                val = 'Ottobre';
                break;
            }
            case '11': {
                val = 'Novembre';
                break;
            }
            case '12': {
                val = 'Dicembre';
                break;
            }
            default: {
                val = "Errore";
                break;
            }
        }
        return val;
    }
    const getDay = (value) => {
        let val = "Errore";
        switch(value) {
            case 0: {
                val = "Domenica"
                break;
            }
            case 1: {
                val = "Lunedì"
                break;
            }
            case 2: {
                val = "Martedì"
                break;
            }
            case 3: {
                val = "Mercoledì"
                break;
            }
            case 4: {
                val = "Giovedì"
                break;
            }
            case 5: {
                val = "Venerdì"
                break;
            }
            case 6: {
                val = "Sabato"
                break;
            }
            default: {
                val = "Errore"
                break;
            }
        }
        return val;
    }
    const getData = (data) => {
        const split = data.split('-');
        const mese = getMese(split[1]);
        const newDate = new Date(split[2]+'-'+split[1]+'-'+split[0]);
        const dayofweek = getDay(newDate.getDay());
        const final = dayofweek+' '+split[0]+' '+mese+' '+split[2];
        return final;
    }
    useEffect(() => {
        const getFestivi = () => {
            makeRequest.get('/pren/checkApertura/')
            .then(res => setFestivi(res.data))
            .catch(err => console.log(err))
        }
        getFestivi();
        const getToday = () => {
            const dataoggi = new Date();
            const currentYear = dataoggi.getFullYear();
            const currentMonth = dataoggi.getMonth()+1;
            const day = dataoggi.getDate();
            const datafinale = (day < 10 ? '0'+day : day)+'-'+(currentMonth < 10 ? '0'+currentMonth : currentMonth)+'-'+currentYear;
            setToday(datafinale);
        }
        getToday();

    }, [])
    useEffect(() => {
        setLast();
        console.log(prensel.length);
        const getLastService = () => {
            if(show === true) {
                const splitdata = prensel?.Data?.split("-");
                const params = {
                    Data: splitdata[2]+'-'+splitdata[1]+'-'+splitdata[0],
                    Nome: prensel?.Nome,
                    Cognome: prensel?.Cognome,
                    Cellulare: prensel?.Cellulare
                }
                console.log('entro mai last?')
                makeRequest.post('/pren/last/', params)
                .then(res=> {
                    console.log(res.data);
                    setLast(res.data)
                })
                .catch(err=>console.log(err))
            }
        }
        getLastService();
    },[prensel])
    const [prenotazioni, setPrenotazioni] = useState([])
    const [orari, setOrari] = useState([
        {ora: '08:00'},
        {ora: '09:00'},
        {ora: '10:00'},
        {ora: '11:00'},
        {ora: '12:00'},
        {ora: '13:00'},
        {ora: '14:00'},
        {ora: '15:00'},
        {ora: '16:00'},     
        {ora: '17:00'},
        {ora: '18:00'},
        {ora: '19:00'},
    ])
    const [giorni, setGiorni] = useState([
    ])
    useEffect(() => {
        const getGiorni = () => {
            const arrDate = [];
            for(let i = 0; i < 7; i++) {
                let open = true;
                const dataoggi = new Date();
                const numofDays = ((7 - dataoggi.getDay()) % 7 + 1*i) % 7;
                const newDate = dataoggi.getDate() + numofDays;
                dataoggi.setDate(newDate);
                for(let b = 0; b < tableid; b++) 
                    dataoggi.setDate(dataoggi.getDate()+7);
                const currentYear = dataoggi.getFullYear();
                const currentMonth = dataoggi.getMonth()+1;
                const day = dataoggi.getDate();
                const datafinale = (day < 10 ? '0'+day : day)+'-'+(currentMonth < 10 ? '0'+currentMonth : currentMonth)+'-'+currentYear;
                const giorno = getDay(i);
                if(i === 0 || i === 1)
                    open = false;
                arrDate.push({giorno: giorno, data: datafinale, open: open});
            }
            const arrDate2 = handleApertura(arrDate);
            setGiorni(arrDate2);
        }
        const getPrenotazioni = () => {
            makeRequest.get('/pren/calendario/')
            .then(res=>setPrenotazioni(res.data))
            .catch(err=>console.log(err));
        }
        getPrenotazioni();
        getGiorni();
    },[festivi, tableid, prenotazioni])
    return showScheda ? 
        (
            <>
                <Schede open={showScheda} show={setShowScheda}/>
            </>
        )
        :
        (
        <>
        <div className="gradientground">
            <div className='d-flex justify-content-center align-item-center'>
            <Container style={{marginTop: '3rem', marginBottom: '5rem'}}>
                <Row>
                    <Logo/>
                </Row>
                <Row className="wrapper">
                    <Col xs={12}>
                        <NavBar active={{path: '/calendario'}} showScheda={setShowScheda}/>
                    </Col>
                    <h1 style={{textAlign: 'center', width: '100%', background: 'pink', padding: '1rem'}}>Calendario</h1>
                        <Col style={{ background: '#12c2e9', textAlign: 'left'}} xs={6}>
                            <Button style={{marginTop: '1rem', marginBottom: '1rem'}} variant="light" onClick={handleBack} disabled={back}><ArrowLeft/> Indietro</Button>
                        </Col>
                        <Col style={{background: '#12c2e9', textAlign: 'right'}} xs={6}>
                            <Button style={{marginTop: '1rem', marginBottom: '1rem'}} variant="light" onClick={handleForward}>Avanti <ArrowRight/></Button>
                        </Col>
                        <Col xs={12} style={{overflow: 'scroll'}}>
                        <Table className="Calendario">
                            <thead>
                                <tr style={{verticalAlign: 'middle', textAlign: 'center'}}>
                                    <th>Orari</th>
                                {(() => {
                                    let arr = [];
                                    giorni.map((giorno) => {
                                        return arr.push(<th className={`${today === giorno.data ? 'dayToday' : ''}`}>{giorno.giorno} <br/> {giorno.data}</th>)
                                    });
                                     return arr;
                                })()}
                                </tr>
                            </thead>
                            <tbody>
                                {(() => {
                                    let arr2 = [];
                                    orari.map((ora, index, elements) => {
                                        let count = 0;
                                        let arr = [];
                                        arr.push(<td><b>{ora.ora}</b></td>)
                                        giorni.map((giorno) => {
                                            let trovato = false;
                                            if(!giorno.open)
                                                return arr.push(<td style={{background: '#f64f59', border:'red'}}> </td>)
                                            let arr3=[];
                                            prenotazioni?.map((pren)  => {
                                                if(pren.Data === giorno.data) {
                                                    if(pren.Ora >= ora.ora && pren.Ora < elements[index+1].ora) {
                                                        trovato = true;
                                                        arr3.push(<Button id={pren.ID} onClick={handlePren} className="calendarioOra" variant="light"><p style={{textAlign: 'left', color: 'red'}}>{pren.Signed ? <CheckLg style={{fontSize: '1.5em', color: 'green'}}/> : null}<b>*{getOperatore(pren.Operatore)}</b></p><b>{pren.Ora}</b> - {pren.Nome} {pren.Cognome}: {pren.Servizi} Note: {pren.Note}</Button>)
                                                        count++;
                                                    }
                                                   //arr.push(<td>{arr3}</td>)
                                                }
                                                return false;
                                            });

                                            if(trovato === false) 
                                                arr.push(<td></td>);
                                            else 
                                                arr.push(<td>{arr3}</td>)
                                            return false;
                                        });
                                        return arr2.push(<tr style={{textAlign: 'center'}}>{arr}</tr>)
                                    })
                                    return arr2;
                                })()}
                                </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            </div>
            <Footer/>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modifica Prenotazione</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={6}>
                                    <FloatingLabel label="Nome">
                                    <Form.Control
                                        value={prensel.Nome}
                                        key="Nome"
                                        style= {{marginTop: '1em'}}
                                        id="nome"
                                        type="text"
                                        placeholder="Nome..."
                                        onChange={(e) => { const val = e.currentTarget.value; setPren({...prensel, Nome: val})}}
                                    />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={6}>
                                    <FloatingLabel label="Cognome">
                                    <Form.Control
                                        value={prensel.Cognome}
                                        key="Cognome"
                                        style= {{marginTop: '1em'}}
                                        id="cognome"
                                        type="text"
                                        placeholder="Cognome..."
                                        onChange={(e) => { const val = e.currentTarget.value; setPren({...prensel, Cognome: val})}}
                                    />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={6}>
                                    <FloatingLabel label="Cellulare">
                                    <Form.Control
                                        value={prensel.Cellulare}
                                        key="Cellulare"
                                        style= {{marginTop: '1em'}}
                                        id="cellulare"
                                        type="phone"
                                        placeholder="Cellulare..."
                                        onChange={(e) => { const val = e.currentTarget.value; setPren({...prensel, Cellulare: val})}}
                                    />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={6}>
                                    <FloatingLabel label="Email">
                                    <Form.Control
                                        value={prensel.Email}
                                        key="Email"
                                        style= {{marginTop: '1em'}}
                                        id="email"
                                        type="email"
                                        placeholder="Email..."
                                        onChange={(e) => { const val = e.currentTarget.value; setPren({...prensel, Email: val})}}
                                    />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12}>
                                    <FloatingLabel label="Servizi">
                                    <Form.Control as="textarea" rows={3}
                                        value={prensel.Servizi}
                                        key="Servizi"
                                        style= {{marginTop: '1em'}}
                                        id="servizi"
                                        type="text"
                                        placeholder="Servizi..."
                                        onChange={(e) => { const val = e.currentTarget.value; setPren({...prensel, Servizi: val})}}
                                    />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12}>
                                    <FloatingLabel label="Note">
                                    <Form.Control as="textarea" rows={3}
                                        value={prensel.Note}
                                        key="Note"
                                        style= {{marginTop: '1em'}}
                                        id="note"
                                        type="text"
                                        placeholder="Note..."
                                        onChange={(e) => { const val = e.currentTarget.value; setPren({...prensel, Note: val})}}
                                    />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={4}>
                                    <Form.Select defaultValue={prensel.Giorno} key="dayofweek" style= {{marginTop: '1em'}} onChange={(e) => handleChangeDay(e)}>
                                        <option value="1">Lunedì</option>
                                        <option value="2">Martedì</option>
                                        <option value="3">Mercoledì</option>
                                        <option value="4">Giovedì</option>
                                        <option value="5">Venerdì</option>
                                        <option value="6">Sabato</option>
                                        <option value="0">Domenica</option>                        
                                    </Form.Select>
                                </Col>
                                <Col xs={4}>
                                    <Form.Select value={datavalue} key="dateofweek" style= {{marginTop: '1em'}} onChange={(e) => handleChangeDate(e)}>
                                    <option hidden disabled selected value="0">Seleziona data...</option>
                                    {nextday.map((item) => {
                                        return (<option value={item}>{item}</option>)
                                    })}                       
                                    </Form.Select>
                                </Col>
                                <Col xs={4}>
                                    <Form.Select disabled = {(disabledhour || datavalue === 0 ? true : false)}  defaultValue={prensel.Ora} key="hourweek" style= {{marginTop: '1em'}} onChange={(e) => { const value = e.currentTarget.value; setPren({...prensel, Ora: value})}}>
                                    {nexthour.map((item) => {
                                        if(item.Attivo === 1) {
                                            return (<option selected={item.RifOra === prensel.Ora ? true : false} value={item.RifOra}>{item.RifOra}</option>)
                                        }
                                        return false;
                                    })}
                                    </Form.Select>
                                </Col>
                                <Col xs={6}>
                                    <FloatingLabel label="Prezzo">
                                    <Form.Control
                                        value={prensel.Prezzo}
                                        key="Prezzo"
                                        style= {{marginTop: '1em'}}
                                        id="prezzo"
                                        type="phone"
                                        placeholder="€..."
                                        onChange={(e) => { const val = e.currentTarget.value; setPren({...prensel, Prezzo: val})}}
                                    />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        checked = {prensel.Signed}
                                        label = {<p>Pagato</p>}
                                        style= {{marginTop: '1em'}}
                                        id="signed"
                                        onChange={(e) => { const val = e.currentTarget.checked; setPren({...prensel, Signed: val})}}
                                    />
                                </Col>
                                {(last ? <Col xs={12}>
                                    <p>Il suo ultimo servizio è stato il <b>{last.Data}</b>: Ha pagato <b>{last.Prezzo}</b> per <b>{last.Servizi}</b></p>
                                </Col> : null)}
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        {confirm ? <Confirm params={{color: 'black', background: 'white'}} text={{title: 'Scheda creata', val: 'La scheda è stata creata con successo!'}}/> : null}
                        {!schedaExist ?
                        <Button variant="warning" onClick={handleCrea}>
                            Crea Scheda
                        </Button>
                        : null}
                        <Button variant="danger" onClick={handleDelete}>
                            Elimina
                        </Button>
                        <Button variant="success" onClick={handleAvvisa}>
                            Avvisa
                        </Button>
                        <Button variant="primary" onClick={handleSave}>
                            Salva
                        </Button>
                    </Modal.Footer>
                </Modal>
        </div>
        </>
    );
}


export default Calendario;