import {React, useState} from 'react'
import Toast from 'react-bootstrap/Toast';
function Confirm({text, params}) {
    const [show, setShow] = useState(true);
    return(
                <Toast className="position-absolute" style={{background: params.background, color: params.color}} onClose={() => setShow(false)} show={show} delay={5000} autohide>
                    <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">{text.title}</strong>
                    <small>2 secondi fa</small>
                    </Toast.Header>
                    <Toast.Body style={{color: '#fff'}}>
                        <p style={{color: params.color}}>{text.val}</p>
                    </Toast.Body>
                </Toast>
    );
}
export default Confirm;