import React, { useState, useEffect } from 'react';
import NavBar from '../Global/NavBar';
import Footer from '../Footer/Footer';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Logo from '../Global/Logo';
import Card from 'react-bootstrap/esm/Card';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import { makeRequest } from '../Global/makeRequest';
import Schede from '../Schede/Schede';
import Find from '../Global/Find';
import Confirm from '../Global/Confirm';
import { uSocket } from '../Socket/Socket';

function Prenota() {
    const [showScheda, setShowScheda] = useState(false);
    const [prensel, setPren] = useState([])
    const [confirm, showConfirm] = useState({show: false});
    const [listacustomer, setListaCustomer] = useState([]);
    const [datavalue, setDatavalue] = useState(0);
    const [nextday, setNextDay] = useState([])
    const [nexthour, setNextHour] = useState([])
    const [disabledhour, setDisabledHour] = useState(false)
    const [festivi, setFestivi] = useState([])
    const [operatori, setOperatori] = useState([])
    const [showFind, setShowFind] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [tab, setTab] = useState(-1);
    const handleClicked = (id) => {
        const newarr = listacustomer.filter(item=> item.ID == id.id)
        setPren({...prensel, Nome: newarr[0]?.Nome, Cognome: newarr[0]?.Cognome, Cellulare: newarr[0]?.Cellulare, Email: newarr[0]?.Email});
        handleFalse();
    }
    const handleKey = event => {
        switch(event.key) {
            case 'ArrowUp': {
              if(listacustomer?.length && tab >= 0)
                setTab(tab-1);
              break;
            }
            case 'ArrowDown': {
              if(listacustomer?.length - 1 > tab)
                setTab(tab+1);
                break;
            }
            case 'Enter': {
              let id = undefined;
              listacustomer.map((item, index) => {
                if(index === tab)
                  id = item.ID; 
                return item;
              })
              console.log(id);
              handleClicked({id: id})
              break;
            }
            default: {
              setTab(-1)
              break;
            }
        }
    };
    const [search, setSearch] = useState("");
    const handleFalse = () => {
        setListaCustomer(null);
        setTab(-1);
        setShowFind(false);
        setSearch('');
    }
    const handleChange = (event) => {
        setSearch(prev => event.target.value);
        let value=null;
        if(event.target.value?.length) {
            value = event.target.value;
            makeRequest.post("/find/get/",{value})
            .then((res) => {
                const newarr = res.data.map((item, index)=>{
                    return {...item, ID: index}
                })
                setListaCustomer(newarr);
            })
            .catch((err) => setListaCustomer([]))
            setShowFind(true);
        } else {
            setListaCustomer(null);
            setShowFind(false);
        }
    }
    useEffect(() => {
        if(confirm.show === true) {
            setTimeout(() => {
                showConfirm({show: false})
                setDisabledButton(false);
            }, 3000)
        }
    },[confirm])
    useEffect(() => {
        const getFestivi = () => {
            makeRequest.get('/pren/checkApertura/')
            .then(res => setFestivi(res.data))
            .catch(err => console.log(err))
        }

        const getOperatori = () => {
            makeRequest.get('/operator/getnomi')
            .then(res => setOperatori(res.data))
            .catch(err => console.log(err))            
        }
        getFestivi();
        getOperatori();
    },[])
    const handleFindCustomer = (e) => {
        const value = e.currentTarget.value; 
        setPren({...prensel, Nome: value});
    }
    const checkEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(email) ) {
            return true;
        }
        else {
            return false;
        }
    }
    function validateName(name) {
        var isValidName = true;
        if(/[!@#$%^&*(),.?":{}|<>]/g.test(name) ||/* !/^[A-Z]/.test(name) ||*/ /\d+/g.test(name)) {
          isValidName = false;
        }
        return isValidName;
    }
    const checkPren = () => {
        if(prensel?.Nome?.length >= 2 && validateName(prensel?.Nome)) {
            if(prensel?.Cognome?.length >=2 && validateName(prensel?.Cognome)) {
                if(String(prensel?.Cellulare).length === 10 && !isNaN(parseInt(prensel?.Cellulare))) {
                    if(checkEmail(prensel?.Email)) {
                        if(prensel?.Data?.length === 10) {
                            if(prensel?.Ora?.length === 5) {
                                if(prensel?.Servizi?.length > 2) {
                                    sendPren();
                                } else {
                                    showConfirm({color: 'white', background: 'red', show: true, title: "Si è verificato un errore", text: "Inserisci dei servizi"});                             
                                }
                            } else {
                                showConfirm({color: 'white', background: 'red', show: true, title: "Si è verificato un errore", text: "Seleziona un orario corretto"});
                            }
                        } else {
                            console.log(prensel?.Data?.length);
                            showConfirm({color: 'white', background: 'red', show: true, title: "Si è verificato un errore", text: "Seleziona una data corretta"});
                        }
                    } else {
                        showConfirm({color: 'black', background: 'red', show: true, title: "Si è verificato un errore", text: "Inserisci un'email corretta"});
                    }
                } else {
                    showConfirm({color: 'white', background: 'red', show: true, title: "Si è verificato un errore", text: "Inserisci un numero di cellulare corretto"});
                }
            } else {
                showConfirm({color: 'white', background: 'red', show: true, title: "Si è verificato un errore", text: "Inserisci un cognome valido"});
            }
        } else {
            showConfirm({color: 'white', background: 'red', show: true, title: "Si è verificato un errore", text: "Inserisci un nome valido"});
        }
    }
    const sendPren = async () => {
        let newdata = dataymd(prensel.Data);
        const params = [
            newdata, prensel.Giorno, prensel.Ora, prensel.Operatore, prensel.Nome, prensel.Cognome, prensel.Cellulare, prensel.Email, prensel.Note, prensel.Servizi, 0, 0
        ];
        setDisabledButton(true);
        makeRequest.post('/pren/sendpren/', {params})
        .then(res=> {
            if(typeof res.data.error === 'undefined') {
                if (typeof window !== 'undefined') {
                    showConfirm({color: 'black', background: 'white', show: true, title: "Prenotazione inviata", text: "Prenotazione inviata con successo, accettala nella Dashboard"});
                    setDisabledButton();
                    setPren([]);
                    setDatavalue(0);
                    setDisabledHour(true);
                }
            } else {
                console.log(res);
                showConfirm({color: 'white', background: 'red', show: true, title: "Si è verificato un errore", text: "La Prenotazione non è stata inviata, riprova"});
                setDisabledButton(false);
            }
        })
        .catch(error => {
            showConfirm({color: 'white', background: 'red', show: true, title: "Si è verificato un errore", text: "La Prenotazione non è stata inviata, riprova"});
            console.log(error)
            setDisabledButton(false);
        })
    }
    const dataymd = (data) => {
        const split = data.split('-');
        return split[2]+'-'+split[1]+'-'+split[0];
    }
    const createListHour = async(data, operatore, ora, defaultVal) => {
        let arrStandard = [];
        let arrOperatore = [];
        let arrOccupate = [];
        let arrOperDisabled = []
        let arrFinale = [];
        await makeRequest.post('/pren/standard/', {operatore})
        .then(res => arrStandard = res.data)
        .catch(err => console.log(err))
        await makeRequest.post('/pren/operatore/', {operatore, data})
        .then(res => arrOperatore = res.data)
        .catch(err => console.log(err))
        await makeRequest.post('/pren/prenoperatore/', {operatore, data})
        .then(res => arrOccupate = res.data)
        .catch(err => console.log(err))
        await makeRequest.post('/pren/operdisabled/', {operatore, data})
        .then(res => arrOperDisabled = res.data)
        .catch(err => console.log(err))
        data = dataymd(data);
        let entro = false;
        arrFinale = arrStandard.map((standard) => {
            for(var i = 0; i < arrOperDisabled.length; i++) {
                if(arrOperDisabled[i].Data === data) {
                    if(arrOperDisabled[i].RifOperatore === operatore) {
                        if(arrOperDisabled[i].Attivo == 0) {
                            entro = true;
                            return {...standard, Attivo: 0}
                        }
                    }
                }
                //return false;
            }
            if(standard.Attivo === 0) {
                for(var i = 0; i < arrOperatore.length; i++) {
                    if(arrOperatore[i].Ora === standard.RifOra) {
                        if(arrOperatore[i].Attivo == 1) {
                            for(var b = 0; b < arrOccupate.length; b++) {
                                if(arrOccupate[b].Data === arrOperatore[i].Data) {
                                    if(defaultVal) {
                                        if(arrOccupate[b].Data === data) {
                                            if(arrOccupate[b].Ora == ora) {
                                                if(arrOccupate[b].Operatore === operatore) {
                                                    return {...standard, Attivo: 1}
                                                }
                                            }
                                        }
                                    }
                                    if(arrOccupate[b].Ora === arrOperatore[i].Ora) {
                                        return {...standard, Attivo: 0}
                                    } else {
                                        return {...standard, Attivo: 1}
                                    }
                                } else {
                                    return {...standard, Attivo: 1}
                                }
                            }
                        }
                        return {...standard, Attivo: 1}
                    }
                }
            } else if(standard.Attivo === 1) {
                for(var i = 0; i < arrOperatore.length; i++) {
                    if(arrOperatore[i].Ora === standard.RifOra) {
                        if(arrOperatore[i].Attivo == 0) {
                            return {...standard, Attivo: 0}
                        }
                    }
                }
                for(var i = 0; i < arrOccupate.length; i++) {
                    if(defaultVal) {
                        if(standard.RifOra === arrOccupate[i].Ora) {
                            if(arrOccupate[i].Data === data) {
                                if(arrOccupate[i].Ora === ora) {
                                    if(arrOccupate[i].Operatore === operatore) {
                                        return {...standard, Attivo: 1}
                                    }
                                }
                            }
                        }
                    }
                    if(arrOccupate[i].Ora === standard.RifOra) {
                        return {...standard, Attivo: 0}
                    }
                }
            }
            return standard;
        });
        if(entro)
            setDisabledHour(true);
        else
            setDisabledHour(false);
        setNextHour(arrFinale);
        return arrFinale;
    }
    const checkFestivi = (data) => {
        let trovato = false;
        festivi.map((item) => {
            if(item.Festivo === data)
                return trovato = true;
            return false;
        })
        return trovato;
    }
    const createListDate = (value) => {
        const dataoggi = new Date();
        let i  = 0;
        const arrDate = [];
        const numofDays = ((7 - dataoggi.getDay()) % 7 + 1*value) % 7;
        const newDate = dataoggi.getDate() + numofDays;
        dataoggi.setDate(newDate);
        while(i < 10) {
            if(i > 0)
                dataoggi.setDate(dataoggi.getDate()+7);
            const currentYear = dataoggi.getFullYear();
            const currentMonth = dataoggi.getMonth()+1;
            const day = dataoggi.getDate();
            const datafinale = (day < 10 ? '0'+day : day)+'-'+(currentMonth < 10 ? '0'+currentMonth : currentMonth)+'-'+currentYear;
            if(!checkFestivi(datafinale)) {
                arrDate.push(datafinale)
            } else {
                if(i === 0)
                    dataoggi.setDate(dataoggi.getDate()+7);
                i--;
            }
            i++;
        }

        setNextDay(arrDate);
        return arrDate;
    }    
    const handleChangeDay = (e) => {
        const value = e.currentTarget.value
        createListDate(value)  
        setDatavalue(0);
        setPren({...prensel, Giorno: value})
    }
    const handleChangeDate = (e) => {
        const value = e.currentTarget.value
        setDatavalue(value);
        createListHour(dataymd(value), prensel.Operatore, prensel.Ora, false)
        setPren({...prensel, Data: value});
    }
    return showScheda ? 
        (
            <>
                <Schede open={showScheda} show={setShowScheda}/>
            </>
        )
        :
        (
        <>
        <div className="gradientground">
             <div className='d-flex justify-content-center align-item-center'>
             <Container style={{marginTop: '3rem', marginBottom: '5rem'}}>
                 <Row>
                     <Logo/>
                 </Row>
                 <Row style={{paddingBottom: '2rem'}} className="wrapper">
                     <Col xs={12}>
                         <NavBar osocket={uSocket} active={{path: '/prenota'}} showScheda={setShowScheda}/>
                     </Col>
                     <Col xs={12}>
                         <Card style={{width: '100%', height: '100%'}}>
                             <Card.Body>
                                 <Card.Title style={{width: '100%', background: 'pink', padding: '1rem'}}>Prenotazione Cliente</Card.Title>
                                        <FloatingLabel
                                            label={<><img src="./search.svg"/><span> Ricerca cliente</span></>}
                                        >
                                        <Form.Control
                                            onFocus={() => setShowFind(true)}
                                            onBlur={() => setTimeout(() => {setShowFind(false)},200)}
                                            onKeyDown={handleKey}
                                            key="find"
                                            type="text"
                                            id="find"
                                            placeholder=""
                                            onChange={(e) => handleChange(e)}
                                                    //   onChange={(e) => {setUser(e.target.value)}}
                                        />
                                        </FloatingLabel>
                                            {showFind ? <Find tab={tab} rows={listacustomer} show={handleClicked} /> : null}
                                        <FloatingLabel label="Nome">
                                            <Form.Control
                                                key="Nome"
                                                style= {{marginTop: '1em'}}
                                                id="nome"
                                                type="text"
                                                list="data"
                                                placeholder="Nome..."
                                                value={prensel.Nome}
                                                onChange={(e) => { handleFindCustomer(e)}}
                                            />
                                        </FloatingLabel>
                                        <FloatingLabel label="Cognome">
                                            <Form.Control
                                                key="Cognome"
                                                style= {{marginTop: '1em'}}
                                                id="cognome"
                                                type="text"
                                                placeholder="Cognome..."
                                                value={prensel.Cognome}
                                                onChange={(e) => { const value = e.currentTarget.value; setPren({...prensel, Cognome: value})}}
                                            />
                                        </FloatingLabel>
                                        <FloatingLabel label="Cellulare">
                                            <Form.Control
                                                key="Cellulare"
                                                style= {{marginTop: '1em'}}
                                                id="cellulare"
                                                type="phone"
                                                placeholder="Cellulare..."
                                                value={prensel.Cellulare}
                                                onChange={(e) => { const value = e.currentTarget.value; setPren({...prensel, Cellulare: value})}}
                                            />
                                        </FloatingLabel>
                                        <FloatingLabel label="Email">
                                            <Form.Control
                                                key="Email"
                                                style= {{marginTop: '1em'}}
                                                id="email"
                                                type="email"
                                                placeholder="Email..."
                                                value={prensel.Email}
                                                onChange={(e) => { const value = e.currentTarget.value; setPren({...prensel, Email: value})}}
                                            />
                                        </FloatingLabel>
                                        <Form.Select key="operator" style= {{marginTop: '1em'}} onChange={(e) => { const value = e.currentTarget.value; setPren({...prensel, Operatore: value})}}>
                                            <option selected disabled hidden>Seleziona operatore...</option>
                                            {operatori.map((item) => {
                                                return (<option value={item.ID}>{item.Nome}</option>)
                                            })}                       
                                        </Form.Select>                                      
                                        <Form.Select disabled={prensel.Operatore ? false : true} defaultValue={prensel.Giorno} key="dayofweek" style= {{marginTop: '1em'}} onChange={(e) => handleChangeDay(e)}>
                                            <option selected disabled hidden>Seleziona giorno...</option>
                                            <option value="1">Lunedì</option>
                                            <option value="2">Martedì</option>
                                            <option value="3">Mercoledì</option>
                                            <option value="4">Giovedì</option>
                                            <option value="5">Venerdì</option>
                                            <option value="6">Sabato</option>
                                            <option value="0">Domenica</option>                        
                                        </Form.Select>
                                        <Form.Select disabled = {prensel.Giorno ? false : true} value={datavalue} key="dateofweek" style= {{marginTop: '1em'}} onChange={(e) => handleChangeDate(e)}>
                                        <option hidden disabled selected value="0">Seleziona data...</option>
                                            {nextday.map((item) => {
                                                return (<option value={item}>{item}</option>)
                                        })}                                
                                        </Form.Select>
                                        <Form.Select disabled = {(disabledhour || datavalue === 0) ? true : false}  defaultValue={prensel.Ora} key="hourweek" style= {{marginTop: '1em'}} onChange={(e) => { const value = e.currentTarget.value; setPren({...prensel, Ora: value})}}>
                                            <option selected disabled hidden>Seleziona ora...</option>
                                            {nexthour.map((item) => {
                                                if(item.Attivo === 1) {
                                                    return (<option selected={item.RifOra === prensel.Ora ? true : false} value={item.RifOra}>{item.RifOra}</option>)
                                                }
                                                return false;
                                            })}              
                                        </Form.Select>
                                        <Form.Control as="textarea" rows={3}
                                            key="notarapida"
                                            style= {{marginTop: '1em'}}
                                            id="note"
                                            onChange={(e) => { const value = e.currentTarget.value; setPren({...prensel, Note: value})}}
                                            placeholder="Note..."
                                        />
                                        <Form.Control as="textarea" rows={3}
                                            key="servizi"
                                            style= {{marginTop: '1em'}}
                                            id="servizi"
                                            onChange={(e) => { const value = e.currentTarget.value; setPren({...prensel, Servizi: value})}}
                                            placeholder="Servizi..."
                                        />
                                        <Button disabled={disabledButton} onClick={checkPren} style={{marginTop: '1rem'}} variant="light">Prenota</Button>
                                        {confirm.show ? <Confirm params={{color: confirm.color, background: confirm.background}} text={{title: confirm.title, val: confirm.text}}/> : null}
                             </Card.Body>
                         </Card>
                     </Col>
                 </Row>
             </Container>
             </div>
             <Footer/>
         </div>
    </>
    );
}


export default Prenota;