import {React, useEffect, useState} from 'react';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Logo from '../Global/Logo';
import NavBar from '../Global/NavBar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Footer from '../Footer/Footer';
import Modal from 'react-bootstrap/esm/Modal';
import Form from 'react-bootstrap/esm/Form';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import { makeRequest } from '../Global/makeRequest';
import { Folder2Open } from 'react-bootstrap-icons';
import Schede from '../Schede/Schede';
import { uSocket } from '../Socket/Socket';

function Clienti() {
    const [showScheda, setShowScheda] = useState(false);
    const [key, setKey] = useState('tutti');
    const [schede, setSchede] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [show, setShow] = useState(false);
    const [newscheda, setNewScheda] = useState([]);
    const [allcustomer, setAllCustomer] = useState([])
    const [recentcustomer, setRecentCustomer] = useState([])
    const [oldcustomer, setOldCustomer] = useState([])
    const [retrieveScheda, setRetrieve] = useState(false);
    useEffect(()=>{
        makeRequest.get('/schede/get/')
        .then(res => setSchede(res.data))
        .catch(err => console.log(err))
    },[retrieveScheda])
    useEffect(() => {
        uSocket?.on('schedaDelete', () => {
            console.log('entro?')
            setRetrieve(!retrieveScheda);
        })
    },[])
    useEffect(() => {
        makeRequest.get('/schede/get/')
        .then(res => setSchede(res.data))
        .catch(err => console.log(err))
        makeRequest.get('/pren/gettutti/')
        .then(res => setAllCustomer(res.data))
        .catch(err => console.log(err))
        makeRequest.get('/pren/getold/')
        .then(res => setOldCustomer(res.data))
        .catch(err => console.log(err))
        makeRequest.get('/pren/getnew/')
        .then(res => setRecentCustomer(res.data))
        .catch(err => console.log(err))
    },[])
    const handleOpenScheda = (e) => {
        const id = e.currentTarget.id;
        setShowScheda({id:id});
    }
    const handleNewScheda = () => {
        setDisabled(true);
        makeRequest.post('/schede/new/',{newscheda})
        .then(res => {
            setShow(false);
            setDisabled(false);
            setRetrieve(!retrieveScheda);
            setSchede([])
        })
        .catch(err => console.log(err))
    }
    const handleClose = () => {
        setNewScheda([]);
        setShow(false);
    }
    return showScheda ? 
        (
            <>
                <Schede open={showScheda} show={setShowScheda}/>
            </>
        )
        : (
            <>
                <div className="gradientground">
                    <div className='d-flex justify-content-center align-item-center'>
                    <Container style={{marginTop: '3rem', marginBottom: '5rem'}}>
                        <Row>
                            <Logo/>
                        </Row>
                        <Row className="wrapper">
                            <Col xs={12}>
                                <NavBar active={{path: '/clienti'}} showScheda={setShowScheda}/>
                            </Col>
                            <Col xs={12}>
                                <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="mb-3"
                                >
                                    <Tab style={{padding: '2rem'}} className="" eventKey="tutti" title="Tutti">
                                        <Card style={{overflow: 'scroll', width: '100%', height: '100%'}}>
                                            <Card.Body>
                                                <Card.Text>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Nome</th>
                                                                <th>Cognome</th>
                                                                <th>Cellulare</th>
                                                                <th>Email</th>
                                                                <th>Ultimo servizio</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {(()=> {
                                                                const arr = [];
                                                                let count = 1;
                                                                allcustomer.map((item) => {
                                                                    const arr2 = [];
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{count}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Nome}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Cognome}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Cellulare}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Email}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Data} {item.Ora}</td>)
                                                                    count++;
                                                                    return arr.push(<tr>{arr2}</tr>)
                                                                });
                                                                return arr;
                                                            })()}
                                                        </tbody>
                                                    </Table>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab style={{padding: '2rem'}} eventKey="recenti" title="Recenti">
                                    <Card style={{overflow: 'scroll', width: '100%', height: '100%'}}>
                                            <Card.Body>
                                                <Card.Text>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Nome</th>
                                                                <th>Cognome</th>
                                                                <th>Cellulare</th>
                                                                <th>Email</th>
                                                                <th>Ultimo servizio</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {(()=> {
                                                                const arr = [];
                                                                let count = 1;
                                                                recentcustomer.map((item) => {
                                                                    const arr2 = [];
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{count}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Nome}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Cognome}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Cellulare}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Email}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Data} {item.Ora}</td>)
                                                                    count++;
                                                                    return arr.push(<tr>{arr2}</tr>)
                                                                });
                                                                return arr;
                                                            })()}
                                                        </tbody>
                                                    </Table>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab style={{padding: '2rem'}} eventKey="passati" title="Passati">
                                    <Card style={{overflow: 'scroll', width: '100%', height: '100%'}}>
                                            <Card.Body>
                                                <Card.Text>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Nome</th>
                                                                <th>Cognome</th>
                                                                <th>Cellulare</th>
                                                                <th>Email</th>
                                                                <th>Ultimo servizio</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {(()=> {
                                                                const arr = [];
                                                                let count = 1;
                                                                oldcustomer.map((item) => {
                                                                    const arr2 = [];
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{count}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Nome}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Cognome}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Cellulare}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Email}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Data} {item.Ora}</td>)
                                                                    count++;
                                                                    return arr.push(<tr>{arr2}</tr>)
                                                                });
                                                                return arr;
                                                            })()}
                                                        </tbody>
                                                    </Table>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab style={{padding: '2rem'}} eventKey="schede" title="Schede">
                                        <Card style={{overflow: 'scroll', width: '100%', height: '100%'}}>
                                            <Card.Body>
                                                <Card.Text>
                                                    <div style={{textAlign: 'right'}}>
                                                        <Button onClick={() => setShow(true)} variant="light">[+]</Button>
                                                    </div>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Nome</th>
                                                                <th>Cognome</th>
                                                                <th>Cellulare</th>
                                                                <th>Visualizza</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(()=> {
                                                                const arr = [];
                                                                let count = 1;
                                                                schede.map((item) => {
                                                                    const arr2 = [];
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{count}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Nome}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Cognome}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}>{item.Cellulare}</td>)
                                                                    arr2.push(<td style={{verticalAlign: 'middle'}}><Button onClick={handleOpenScheda} id={item.ID} variant="light"><Folder2Open/></Button></td>)
                                                                    count++;
                                                                    return arr.push(<tr>{arr2}</tr>)
                                                                });
                                                                return arr;
                                                            })()}
                                                        </tbody>
                                                    </Table>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                    </div>
                    <Footer/>
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nuova Scheda</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={6}>
                                    <FloatingLabel label="Nome">
                                    <Form.Control
                                        value={newscheda.Nome}
                                        key="Nome"
                                        style= {{marginTop: '1em'}}
                                        id="nome"
                                        type="text"
                                        placeholder="Nome..."
                                        onChange={(e) => { const val = e.currentTarget.value; setNewScheda({...newscheda, Nome: val})}}
                                    />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={6}>
                                    <FloatingLabel label="Cognome">
                                    <Form.Control
                                        value={newscheda.Cognome}
                                        key="Cognome"
                                        style= {{marginTop: '1em'}}
                                        id="cognome"
                                        type="text"
                                        placeholder="Cognome..."
                                        onChange={(e) => { const val = e.currentTarget.value; setNewScheda({...newscheda, Cognome: val})}}
                                    />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12}>
                                    <FloatingLabel label="Cellulare">
                                    <Form.Control
                                        value={newscheda.Cellulare}
                                        key="Cellulare"
                                        style= {{marginTop: '1em'}}
                                        id="cellulare"
                                        type="phone"
                                        placeholder="Cellulare..."
                                        onChange={(e) => { const val = e.currentTarget.value; setNewScheda({...newscheda, Cellulare: val})}}
                                    />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12}>
                                    <FloatingLabel label="Scheda">
                                    <Form.Control as="textarea" rows={6}
                                        value={newscheda.Scheda}
                                        key="scheda"
                                        style= {{marginTop: '1em'}}
                                        id="scheda"
                                        type="text"
                                        placeholder="Scheda..."
                                        onChange={(e) => { const val = e.currentTarget.value; setNewScheda({...newscheda, Scheda: val})}}
                                    />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Chiudi
                        </Button>
                        <Button disabled={disabled} variant="primary" onClick={handleNewScheda}>
                            Crea
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
    );
}


export default Clienti;