import React, { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Footer from '../Footer/Footer';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import {Key} from 'react-bootstrap-icons';
import Errore from '../Global/Errore';
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../Auth/authContext';
function Login() {
    const navigate = useNavigate();
    const [username, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [error, showError] = useState(false);
    const [errortext, setText] = useState("");
    const checkError = () => {
        if(username.length >=6) {
            if(password.length >=8) {
                return false;
            } else {
                setText("Inserisci una password valida ( almeno 8 caratteri )");
                showError(true);
                setTimeout(() => {
                    showError(false)
                    setText("");
                }, 3000);
            }
        } else {
            setText("Inserisci un username valido ( almeno 6 caratteri )");
            showError(true);
            setTimeout(() => {
                showError(false)
                setText("");
            }, 3000);
        }
        return true;
    }
    const { logins } = useContext(AuthContext);
    const handleSubmit = async(event) => {
        showError(false);
        setText("");
        event.preventDefault()
        if(checkError() === false) {
            const params = {
                user: username,
                pass: password
            }
            try {
                await logins(params)
                navigate('/')
            }
            catch(err) {
                setText("I dati inseriti non sono validi");
                showError(true);
                setTimeout(() => {
                    showError(false)
                    setText("");
                }, 3000);
            }
        } else {
            console.log('dati errati');
        }
    }
    return (
            <div className="gradientground">
            <Container style={{height: '30rem', width: '100%'}} className="align-items-center justify-content-center d-flex">
                <Row className="gradientcontainer" style={{border: '1px solid #efefef', borderRadius: '4px', padding: '3rem', margin: '0 auto'}}>
                    <Col className="align-items-center justify-content-center d-flex" xs={6}>
                        <Image
                            src='./beautyhairlogo.png'
                            height="200px !important"
                            fluid
                        />
                    </Col>
                    <Col xs={6}>
                        <h1 style={{color: '#efefef'}}>Login</h1>
                        <Form>
                            <FloatingLabel
                            label={<><img src="./person.svg"/><span> Username</span></>}
                            >
                                <Form.Control
                                    key="username"
                                    style= {{marginTop: '1em'}}
                                    type="text"
                                    id="username"
                                    placeholder=""
                                    className={(username.length >= 6 ? 'correct':'')}
                                    onChange={(e) => {setUser(e.target.value)}}
                                />
                            </FloatingLabel>
                            <FloatingLabel
                            label={<><img src="./lock.svg"/><span> Password</span></>}
                            >
                                <Form.Control
                                    key="password"
                                    style= {{marginTop: '1em'}}
                                    type="password"
                                    id="password"
                                    placeholder=""
                                    onChange={(e) => {setPassword(e.target.value)}}
                                    className={(password.length >= 8 ? 'correct':'')}
                                />
                            </FloatingLabel>
                            <Button type="submit" onClick={handleSubmit} variant="light" style= {{width: '100%', marginTop: '1em'}}><span style={{marginLeft: 'auto'}}><Key/></span>Login</Button>
                        </Form>
                    </Col>
                    <Col className='text-left'>
                        {error ? <Errore error={{text: errortext}}/> : null}
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>
    );
}


export default Login;