import {React, useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import { App, PersonPlus, BoxArrowRight, Clock, Calendar2Week, GraphUp, People, PencilSquare, Percent} from 'react-bootstrap-icons';
import Button from 'react-bootstrap/esm/Button';
import Find from './Find';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Auth/authContext';
import { makeRequest } from './makeRequest';
import { uSocket } from '../Socket/Socket';
function NavBar({active, showScheda}) {
  const [dashboardRed, setDashboardRed] = useState(false);
  useEffect(() => {
      makeRequest.get('/pren/get')
      .then(res => {
        if(res.data?.length)
          setDashboardRed(true);
      })
      .catch(err=>setDashboardRed(false))
  }, [])
  useEffect(() => {
    uSocket?.on('peopleSend', () => {
      setDashboardRed(true);
  });
  },[])
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const handleLogout = async() => {
    try {
      await logout()
      navigate('/login');
    }
    catch(err) {
      console.log(err);
    }
  }
  const [findUser, setFindUser] = useState([
    /*{id: 0, name: 'Pierpaolo', person: true, scheda: false},
    {id: 1, name: 'Giada', person: true, scheda: false},
    {id: 2, name: 'Ivan', person: false, scheda: true},
    {id: 3, name: 'Pierpaolo', person: true, scheda: false},
    {id: 4, name: 'Giada', person: true, scheda: false},
    {id: 5, name: 'Ivan', person: false, scheda: true},*/
  ])
  const [showFind, setShowFind] = useState(false);
  const [tab, setTab] = useState(-1);
  const handleKey = event => {
      switch(event.key) {
          case 'ArrowUp': {
            if(findUser?.length && tab >= 0)
              setTab(tab-1);
            break;
          }
          case 'ArrowDown': {
            if(findUser?.length - 1 > tab)
              setTab(tab+1);
              break;
          }
          case 'Enter': {
            let id = undefined;
            findUser.map((item, index) => {
              if(index === tab)
                id = item.ID; 
              return item;
            })
            showScheda({id:id});
            break;
          }
          default: {
            setTab(-1)
            break;
          }
      }
  };
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const handleTrue = () => {
    setShow(true);
  }
  const handleFalse = () => {
    setFindUser(null);
    setTab(-1);
    setShow(false);
  }
  const handleChange = (event) => {
    setSearch(prev => event.target.value);
    let value=null;
    if(event.target.value?.length) {
      value = event.target.value;
      makeRequest.post("/find/getschede/",{value})
      .then((res) => setFindUser(res.data))
      .catch((err) => setFindUser([]))
      setShowFind(true);
    } else {
      setFindUser(null);
      setShowFind(false);
    }
  }
  return (
    <>
    <Navbar variant="light" key='md' expand='md' style={{background: 'inherit'}}>
      <Container fluid>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
            <Navbar.Offcanvas
              style={{background: '#000000'}}
              id={`offcanvasNavbar-expand-md}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-md`}
              placement="end"
              onShow={handleTrue}
              onHide={handleFalse}
            >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{color: '#FFFFFF'}} id={`offcanvasNavbarLabel-expand-md`}>
                <Image
                src="./beautyhairbanner.png"
                width="150"
                rounded
                fluid
                alt="Beauty Hair di Giada Messina a Palermo Logo"
              />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-start flex-grow-1 pe-3">
                <FloatingLabel
                  label={<><img src="./search.svg"/><span> Ricerca cliente</span></>}
                >
                  <Form.Control
                    onFocus={() => setShowFind(true)}
                    onBlur={() => setTimeout(() => {setShowFind(false)},200)}
                    onKeyDown={handleKey}
                    key="find"
                    type="text"
                    id="find"
                    placeholder=""
                    onChange={(e) => handleChange(e)}
                             //   onChange={(e) => {setUser(e.target.value)}}
                  />
                </FloatingLabel>
                {show && showFind ? <Find tab={tab} rows={findUser} show={showScheda} /> : null}
              </Nav>
              <Nav activeKey={active.path} className="justify-content-center flex-grow-1 pe-3">
                <Nav.Link className={(show ? "nav-mobile" : '')} href="/">{(show ? <span><App style={{color: dashboardRed ? 'red' : 'inherit'}}/> Dashboard</span> : <App style={{color: dashboardRed ? 'red' : 'inherit'}}/>)}</Nav.Link>
                <Nav.Link className={(show ? "nav-mobile" : '')} href="/prenota">{(show ? <span><PersonPlus/> Prenota</span> : <PersonPlus/>)}</Nav.Link>
                <Nav.Link className={(show ? "nav-mobile" : '')} href="/calendario">{(show ? <span><Calendar2Week/> Calendario</span> : <Calendar2Week/>)}</Nav.Link>
                <Nav.Link className={(show ? "nav-mobile" : '')} href="/orari">{(show ? <span><Clock/> Orari</span> : <Clock/>)}</Nav.Link>
                <Nav.Link className={(show ? "nav-mobile" : '')} href="/note">{(show ? <span><PencilSquare/> Note</span> : <PencilSquare/>)}</Nav.Link>
                <Nav.Link className={(show ? "nav-mobile" : '')} href="/statistiche">{(show ? <span><GraphUp/> Statistiche</span> : <GraphUp/>)}</Nav.Link>
                <Nav.Link className={(show ? "nav-mobile" : '')} href="/clienti">{(show ? <span><People/> Clienti</span> : <People/>)}</Nav.Link>
                <Nav.Link className={(show ? "nav-mobile" : '')} href="/sconti">{(show ? <span><Percent/> Promozioni</span> : <Percent/>)}</Nav.Link>
              </Nav>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Button onClick = {handleLogout} variant = "light">Logout <BoxArrowRight/></Button>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {showFind ? <Find tab={tab} rows={findUser} show={showScheda}/> : null }
      </>
  );
}

export default NavBar;