import React, {useContext, useState, useEffect} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Dashboard from './Component/Dashboard/Dashboard';
import Login from './Component/Login/Login';
import Calendario from './Component/Calendario/Calendario';
import Clienti from './Component/Clienti/Clienti';
import Statistiche from './Component/Statistiche/Statistiche';
import Orari from './Component/Orari/Orari';
import Note from './Component/Note/Note';
import Prenota from './Component/Prenota/Prenota';
import Schede from './Component/Schede/Schede'
import Sconti from './Component/Sconti/Sconti'
import { AuthContext } from './Component/Auth/authContext';
function App() {
  const { currentUser } = useContext(AuthContext);
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={currentUser === null ? <Navigate to="/login"/>:<Dashboard/>}></Route>
          <Route path="/login" element={currentUser !== null ? <Navigate to="/"/>:<Login/>}></Route>
          <Route path="/calendario" element={currentUser === null ? <Navigate to="/login"/>:<Calendario/>}></Route>
          <Route path="/note" element={currentUser === null ? <Navigate to="/login"/>:<Note/>}></Route>
          <Route path="/clienti" element={currentUser === null ? <Navigate to="/login"/>:<Clienti/>}></Route>
          <Route path="/prenota" element={currentUser === null ? <Navigate to="/login"/>:<Prenota/>}></Route>
          <Route path="/statistiche" element={currentUser === null ? <Navigate to="/login"/>:<Statistiche/>}></Route>
          <Route path="/orari" element={currentUser === null ? <Navigate to="/login"/>:<Orari/>}></Route>
          <Route path="/schede" element={currentUser === null ? <Navigate to="/login"/>:<Schede/>}></Route>
          <Route path="/sconti" element={currentUser === null ? <Navigate to="/login"/>:<Sconti/>}></Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
