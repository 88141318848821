import {React, useState, useEffect} from 'react';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import NavBar from '../Global/NavBar';
import Logo from '../Global/Logo';
import Footer from '../Footer/Footer';
import Button from 'react-bootstrap/esm/Button';
import { makeRequest } from '../Global/makeRequest';
import Card from 'react-bootstrap/esm/Card'
import Form from 'react-bootstrap/esm/Form';
import Confirm from '../Global/Confirm';
import { XLg } from 'react-bootstrap-icons';
import { uSocket } from '../Socket/Socket';

function Schede({open, show}) {
    console.log(open);
    const [idscheda, setIdScheda] = useState(open.id);
    const [scheda, setScheda] = useState([]);
    const [promozione, setPromozione] = useState("");
    const [confirm, showConfirm] = useState(false);
    const handleClose = () => {
        show(false);
    }
    const handleRemove = () => {
        if(window.confirm("Sei sicuro di voler eliminare questa scheda? Non potrà essere recuperata!")) {
            makeRequest.post('/schede/remove/', {idscheda})
            .then(() => {
                uSocket.emit('schedaDelete')
                show(false)

            })
            .catch(show(false))
        }
    }
    const handleRingrazia = () => {
        const text = "Ciao "+scheda?.Nome+", grazie per averci scelto! Ti aspettiamo presto in Salone. Ricordiamo che è possibile prenotare il prossimo servizio sia su https://beautyhairpalermo.it/, sia chiamando a questo numero. Verrai inoltre informata per ogni eventuale sconto o promozione in atto."
        const link = "whatsapp://send?phone=39"+scheda?.Cellulare+"&abid=39"+scheda?.Cellulare+"&text="+text;
        window.location.href = link;
        setTimeout(() => {
            window.location.href = link;
        }, 3000);
    }
    const handlePromozione = () => {
        const text = "Ciao "+scheda?.Nome+" "+promozione?.Messaggio
        const link = "whatsapp://send?phone=39"+scheda?.Cellulare+"&abid=39"+scheda?.Cellulare+"&text="+text;
        window.location.href = link;
        setTimeout(() => {
            window.location.href = link;
        }, 3000);
    }
    const handleSave = () => {
        const schedaval = scheda?.Scheda
        const cellulare = scheda?.Cellulare
        makeRequest.put("/schede/modify/", {idscheda,cellulare,schedaval})
        .then(res => {
            showConfirm(true);
            setTimeout(()=>{
                showConfirm(false);
            },3000)
        })
        .catch(err => console.log(err))
    }
    useEffect(() => {
        console.log(idscheda)
        if(idscheda === null || isNaN(idscheda)) {
            show(false);
        }
        makeRequest.post("/schede/getone/", {idscheda})
        .then(res => { console.log(res.data); setScheda(res.data[0])})
        .catch(err => show(false));
        makeRequest.get("/sconto/get/")
        .then(res => setPromozione(res.data[0]))
        .catch(err => console.log(err))
    },[idscheda])
    return(
        <>
        <div className="gradientground">
            <div className='d-flex justify-content-center align-item-center'>
                <Container style={{marginTop: '3rem', marginBottom: '5rem'}}>
                    <Row>
                        <Logo/>
                    </Row>
                    <Row className="wrapper">
                        <Col xs={12}>
                            <NavBar active={{path: '/clienti'}}/>
                        </Col>
                        <Col xs={12}>
                            <Card>
                                <Card.Title style={{background: 'pink', width: '100%', padding: '1rem'}}>
                                    <Button style={{marginRight: '1rem'}} variant="light" onClick={handleClose}>
                                        <XLg/>
                                    </Button>
                                    {scheda?.Nome} {scheda?.Cognome}</Card.Title>
                                <Card.Body>
                                    <p>Ultima modifica: <b>{scheda?.LastModify}</b></p>
                                    {confirm ? <Confirm params={{color: 'black', background: 'white'}} text={{title: 'Scheda salvata', val: 'La scheda è stata salvata con successo!'}}/> : null}
                                    <Form.Control as="textarea" rows={10}
                                        placeholder="Scheda..."
                                        value={scheda?.Scheda}
                                        onChange={(e) => { const value = e.currentTarget.value; setScheda({...scheda, Scheda: value})}}
                                    >
                                    </Form.Control>
                                    <Form.Control
                                        placeholder="Cellulare..."
                                        value={scheda?.Cellulare}
                                        onChange={(e) => { const value = e.currentTarget.value; setScheda({...scheda, Cellulare: value})}}
                                    >
                                    </Form.Control>
                                    <Button style={{margin: '1rem'}} variant="danger" onClick={handleRemove}>Elimina</Button>
                                    <Button style={{margin: '1rem'}} variant="primary" onClick={handlePromozione}>Promozione</Button>
                                    <Button style={{margin: '1rem'}} variant="success" onClick={handleRingrazia}>Ringrazia</Button>
                                    <Button style={{margin: '1rem'}} variant="light" onClick={handleSave}>Salva Scheda</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer/>
        </div>
        </>
    );
}
export default Schede;