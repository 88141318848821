import React, { useState, useEffect } from 'react';
import NavBar from '../Global/NavBar';
import Footer from '../Footer/Footer';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Logo from '../Global/Logo';
import Card from 'react-bootstrap/esm/Card';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import { PencilSquare } from 'react-bootstrap-icons';
import { makeRequest } from '../Global/makeRequest';
import Confirm from '../Global/Confirm';
import Schede from '../Schede/Schede';
import { uSocket } from '../Socket/Socket';

function Note() {
    const [nota, setNota] = useState('');
    const [confirm, showConfirm] = useState(false);
    const [showScheda, setShowScheda] = useState(false);
    useEffect(() => {
        makeRequest.get('/note/get/')
        .then(res => {console.log(res.data); setNota(res.data.Nota)})
        .catch(err => console.log(err))
    },[]);
    const handleClick = () => {
        makeRequest.put('/note/save/', {nota})
        .then(res => {
            showConfirm(true);
            setTimeout(() => {
                showConfirm(false);
            },3000)
        })
        .catch(err => console.log(err))
    }
    return showScheda ? 
        (
            <>
                <Schede open={showScheda} show={setShowScheda}/>
            </>
        )
        : 
        (
        <>
           <div className="gradientground">
                <div className='d-flex justify-content-center align-item-center'>
                <Container style={{marginTop: '3rem', marginBottom: '5rem'}}>
                    <Row>
                        <Logo/>
                    </Row>
                    <Row className="wrapper">
                        <Col xs={12}>
                            <NavBar active={{path: '/note'}} showScheda={setShowScheda}/>
                        </Col>
                        <Col xs={12}>
                            <Card style={{overflow: 'scroll', width: '100%', height: '100%'}}>
                                <Card.Body>
                                    <Card.Title style={{width: '100%', background: 'pink', padding: '1rem'}}>Note</Card.Title>
                                    <Card.Text>
                                        <Form.Control as="textarea" rows={10}
                                            value={nota}
                                            onChange={(e) => {console.log(e.target.value); setNota(prev => e.target.value)}}
                                            key="notarapida"
                                            style= {{marginTop: '1em'}}
                                            id="note"
                                            placeholder="Scrivi la tua nota..."
                                        />
                                        <Button onClick={handleClick} style={{marginTop: '1rem'}} variant="light"><PencilSquare/> Salva</Button>
                                    </Card.Text>
                                </Card.Body>
                                {confirm ? <Confirm params={{color: 'black', background: 'white'}} text={{title: 'Nota salvata', val: 'La tua nota è stata salvata con successo!'}}/> : null}
                            </Card>
                        </Col>
                    </Row>
                </Container>
                </div>
                <Footer/>
            </div>
       </>
    );
}


export default Note;