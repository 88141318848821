import {createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
    const logins = async(params) => {
        //const res = await axios.post('http://localhost:3001/server/login/login', {params},{withCredentials: true, credentials: 'include'})
 
        const res = await axios.post('https://beautyhairpalermo.it:3001/server/login/login', {params},{withCredentials: true, credentials: 'include'})
        setCurrentUser(res.data.value)
    }
    const logout = async() => {
        //const res = await axios.post('http://localhost:3001/server/login/logout', {},{withCredentials: true, credentials: 'include'})
 
        const res = await axios.post("https://beautyhairpalermo.it:3001/server/login/logout",{},{withCredentials: true, credentials: 'include'})
        setCurrentUser(null);
    }
    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(currentUser));
    }, [currentUser])
    return (
        <AuthContext.Provider value={{currentUser, logins, logout}}>
            {children}
        </AuthContext.Provider>
    )
}