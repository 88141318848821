  export async function HandleLogout(navigate, logout) {
    console.log('arrivo qui')
    try {
        console.log('arrivo qui2')
      await logout()
      navigate('/login');
    }
    catch(err) {
        console.log('arrivo qui3')
      console.log(err);
    }
  }