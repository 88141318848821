import React, { useState, useEffect } from 'react';
import NavBar from '../Global/NavBar';
import Footer from '../Footer/Footer';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Table from 'react-bootstrap/esm/Table';
import Col from 'react-bootstrap/esm/Col';
import Logo from '../Global/Logo';
import Card from 'react-bootstrap/esm/Card';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import { makeRequest } from '../Global/makeRequest';
import Schede from '../Schede/Schede';
import { uSocket } from '../Socket/Socket';
function Statistiche({user, auth}) {
    const [showScheda, setShowScheda] = useState(false);
    const [listapren, setListaPren] = useState([]);
    const [settimana, setListaSett] = useState([]);
    const [giorni, setGiorni] = useState([]);
    const [guadagno, setGuadagno] = useState(0.0);
    const [bestDay, setBest] = useState(0);
    const [dayName, setdayName] = useState('');
    const getDayOfWeek = (d, dayweek) => {
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -7+dayweek : dayweek); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${year}-${month}-${day}`;
    };
    const [datainizio, setStartDate] = useState(formatDate(getDayOfWeek(new Date(), 1)));
    const [datafine, setEndDate] = useState(formatDate(getDayOfWeek(new Date(), 6)));
    const getDates = () => {
        if (datainizio && datafine) {
            const start = new Date(datainizio);
            const end = new Date(datafine);
            const days = Math.floor((end - start) / (1000 * 60 * 60 * 24));
            const dates = [];
            for (let i = 0; i <= days+1; i++) {
                const currentDate = new Date(start.getTime() + i * 24 * 60 * 60 * 1000);
                dates.push(formatDate(currentDate));
            }
            setGiorni(dates);
        }
    };  
    const dataymd = (data) => {
        const split = data.split('-');
        return split[2]+'-'+split[1]+'-'+split[0];
    }
    useEffect(()=> {
        makeRequest.get('/pren/gettutti')
        .then(res=>setListaPren(res.data))
        .catch(err=>console.log(err))
        getDates();
    },[])
    useEffect(() => {
        if(giorni.length) {
            const newArr = listapren.filter(item => dataymd(item.Data) >= giorni[0]  && dataymd(item.Data) <= giorni[6])
            setListaSett(newArr);
            console.log(newArr, listapren, giorni);
        }
    },[listapren, giorni])
    const getDay = (value) => {
        let val = "Errore";
        switch(value) {
            case 0: {
                val = "Domenica"
                break;
            }
            case 1: {
                val = "Lunedì"
                break;
            }
            case 2: {
                val = "Martedì"
                break;
            }
            case 3: {
                val = "Mercoledì"
                break;
            }
            case 4: {
                val = "Giovedì"
                break;
            }
            case 5: {
                val = "Venerdì"
                break;
            }
            case 6: {
                val = "Sabato"
                break;
            }
            default: {
                val = "Errore"
                break;
            }
        }
        return val;
    }
    useEffect(() => {
        let val = 0.0;
        let lun = 0;
        let mar = 0;
        let merc = 0;
        let giov = 0;
        let ven = 0;
        let sab = 0;
        let dom = 0;
        settimana.map((item) => {
            switch(item.Giorno) {
                case 0: { dom++; break; }
                case 1: { lun++; break; }
                case 2: { mar++; break; }
                case 3: { merc++; break; }
                case 4: { giov++; break; }
                case 5: { ven++; break; }
                case 6: { sab++; break; }             
            }
            val+=parseInt(item.Prezzo);
            console.log(item);
        })
        setGuadagno(val);
        const number = [dom, lun, mar, merc, giov, ven, sab];
        console.log(number);
        const index = number.indexOf(Math.max(...number))
        setdayName(getDay(index));
        setBest(number[index]);
    },[settimana])
    return showScheda ? 
        (
            <>
                <Schede open={showScheda} show={setShowScheda}/>
            </>
        )
        : 
        (
        <>
           <div className="gradientground">
                <div className='d-flex justify-content-center align-item-center'>
                <Container style={{marginTop: '3rem', marginBottom: '5rem'}}>
                    <Row>
                        <Logo/>
                    </Row>
                    <Row className="wrapper">
                        <Col xs={12}>
                            <NavBar active={{path: '/statistiche'}} showScheda={setShowScheda}/>
                        </Col>
                        <Col xs={12}>
                            <Card style={{overflow: 'scroll', width: '100%', height: '100%'}}>
                                <Card.Body>
                                    <Card.Title style={{width: '100%', background: 'pink', padding: '1rem'}}>Statistiche</Card.Title>
                                    <Card.Text>
                                        <h1>Prenotazioni totali: <b>{listapren.length}</b></h1>
                                        <hr/>
                                        {settimana.length ? <>
                                        <h3>La tua settimana: <b>{dataymd(giorni[0])}</b> - <b>{dataymd(giorni[6])}</b></h3>
                                        <h5>Numero di clienti settimanali: <b>{settimana.length}</b></h5>
                                        <h5>Il tuo guadagno settimanale: <b>€{guadagno}</b></h5>
                                        <h5>Giornata con più clienti: <b>{dayName} - {bestDay} prenotazioni</b></h5>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Nome e Cognome</th>
                                                    <th>Data e Ora</th>
                                                    <th>Servizi</th>
                                                    <th>Note</th>
                                                    <th>€</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(() => {
                                                    const arr = [];
                                                    let conto = 1;
                                                    settimana.map((item) => {
                                                        arr.push(<tr>
                                                            <td>{conto}</td>
                                                            <td>{item.Nome} {item.Cognome}</td>
                                                            <td>{getDay(item.Giorno)} {item.Data} - {item.Ora}</td>
                                                            <td>{item.Servizi}</td>
                                                            <td>{item.Note}</td>
                                                            <td>{item.Prezzo}€</td>
                                                        </tr>)
                                                        conto++;
                                                    })
                                                    return arr;
                                                })()}
                                            </tbody>
                                        </Table> 
                                        </>
                                        : null}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                </div>
                <Footer/>
            </div>
       </>
    );
}
export default Statistiche;
