import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

function Errore({error}) {
  const [show, setShow] = useState(true);
  return error.id !== null ? (
      <Row>
        <Col>
          <Toast className="position-absolute" bg="danger" onClose={() => setShow(false)} show={show} delay={3000} autohide>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Errore</strong>
              <small>2 secondi fa</small>
            </Toast.Header>
            <Toast.Body style={{color: '#fff'}}>
              {error.text}
            </Toast.Body>
          </Toast>
        </Col>
      </Row>
  ) : <></>;
}
export default Errore;