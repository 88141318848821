import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Form from 'react-bootstrap/esm/Form';
import Logo from '../Global/Logo';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Footer from '../Footer/Footer';
import Card from 'react-bootstrap/esm/Card';
import Confirm from '../Global/Confirm';
import Table from 'react-bootstrap/esm/Table';
import NavBar from '../Global/NavBar';
import Button from 'react-bootstrap/esm/Button';
import { makeRequest } from '../Global/makeRequest';
import Schede from '../Schede/Schede';
import LoadSpinner from '../Global/LoadSpinner';
import { uSocket } from '../Socket/Socket';
function Orari() {
    const [confirm, showConfirm] = useState(false);
    const [operatori,setOperatori] = useState([]);
    const [currentOperator, setCurrentOperator] = useState("0");
    const [operatordisabled, setOperatorDisabled] = useState(false);
    const [listadate, setListaDate] = useState([]);
    const [listapren, setListaPren] = useState([])
    const orari = ['08:00','09:00','10:00','11:00','12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00','19:00']
    const [festivi, setFestivi] = useState([])
    const [listaore, setListaOre] = useState([])
    const [currentDay, setCurrentDay] = useState("-1");
    const [currentDate, setCurrentDate] = useState("0");
    const [showScheda, setShowScheda] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const handleDisable = (e) => {
        const val = e.currentTarget.innerText;
        let attivo = null;
        if(val === "Disabilita giorno") {
            attivo = 0;
            setOperatorDisabled(true);
        } else if(val === "Abilita giorno") {
            attivo = 1;
            setOperatorDisabled(false);
        }
        const datacorr = dataymd(currentDate)
        makeRequest.put("/operator/modifyDate/", {currentOperator, datacorr, attivo})
    }
    const handleClick = (e) => {
        const ora = e.currentTarget.innerText;
        let attivo = null;
        const newarr = listaore.map((item) => {
            if(item.RifOra === ora) {
                attivo = !item.Attivo;
                return {...item, Attivo: !item.Attivo}
            }
            return item;
        })
        setListaOre(newarr);
        if(attivo === true)
            attivo = 1;
        else
            attivo = 0;
        if(currentDate != "0") {
            const datacorr = dataymd(currentDate);
            makeRequest.put("/operator/modifyGiorni/", {currentOperator, datacorr, ora, attivo})
            .then(res => {
                console.log(res);
            })
            .catch(err => console.log(err))
        } else {
            makeRequest.put("/operator/modifyStandard/", {currentOperator, ora, attivo})
            .then(res => {
                console.log(res);
            })
            .catch(err => console.log(err))
        }
    }
    const checkFestivi = (data) => {
        let trovato = false;
        festivi.map((item) => {
            if(item.Festivo === data)
                return trovato = true;
            return false;
        })
        return trovato;
    }
    const dataymd = (data) => {
        const datasplit = data.split("-")
        return datasplit[2]+'-'+datasplit[1]+'-'+datasplit[0];
    }
    const createListDate = (value) => {
        const dataoggi = new Date();
        let i  = 0;
        const arrDate = [];
        const numofDays = ((7 - dataoggi.getDay()) % 7 + 1*value) % 7;
        const newDate = dataoggi.getDate() + numofDays;
        dataoggi.setDate(newDate);
        while(i < 10) {
            if(i > 0)
                dataoggi.setDate(dataoggi.getDate()+7);
            const currentYear = dataoggi.getFullYear();
            const currentMonth = dataoggi.getMonth()+1;
            const day = dataoggi.getDate();
            const datafinale = (day < 10 ? '0'+day : day)+'-'+(currentMonth < 10 ? '0'+currentMonth : currentMonth)+'-'+currentYear;
            if(!checkFestivi(datafinale)) {
                arrDate.push(datafinale)
            } else {
                if(i === 0)
                    dataoggi.setDate(dataoggi.getDate()+7);
                i--;
            }
            i++;
        }
        setListaDate(arrDate);
    }
    const handleOperator = (e) => {
        const id = e.currentTarget.value;
        setIsLoaded(false);
        setCurrentOperator(id);
        setCurrentDay("-1");
        setCurrentDate("0");
    }
    const handleChangeDay = (e) => {
        const id = e.currentTarget.value;
        setCurrentDay(id);
        setCurrentDate("0");
        createListDate(id)
    }
    const handleChangeDate = (e) => {
        const id = e.currentTarget.value;
        setIsLoaded(false);
        setCurrentDate(id);
    }
    useEffect(() => {
        makeRequest.get('/operator/getNomi/')
        .then(res => {
            setOperatori(res.data)
        })
        .catch(err => console.log(err))
        makeRequest.get('/pren/checkApertura/')
        .then(res => setFestivi(res.data))
        .catch(err => console.log(err))
    },[])
    useEffect(() => {
        if(currentOperator != "0" && currentDate == "0") {
            makeRequest.post('/operator/getOreStandard/', {currentOperator})
            .then(res => {
                setListaOre(res.data);
                setIsLoaded(true);
            })
            .catch(err => {
                console.log(err);
            })
        }
    },[currentOperator, currentDate])
    useEffect(() => {
        setListaPren([]);
        if(currentOperator != "0" && currentDate != "0") {
            const datacorr = dataymd(currentDate)
            makeRequest.post('/pren/prendayora/', {currentOperator, datacorr})
            .then(res => {
                console.log(res.data);
                setListaPren(res.data);
            })
            .catch(err => console.log(err))
            makeRequest.post('/operator/getCustomOre/', {currentOperator, datacorr})
            .then(res => {
                const newArr = listaore.map((item) => {
                    for(let i = 0; i < res.data.length; i++) {
                        if(item.RifOra === res.data[i].Ora)
                            return {...item, Attivo: res.data[i].Attivo}
                    }
                    return item;
                })
                setListaOre(newArr);
                setIsLoaded(true);
            })
            .catch(err => {
                console.log(err);
                setIsLoaded(true);
            })
            makeRequest.post('/operator/getDisabled/', {currentOperator, datacorr})
            .then(res => {
                setOperatorDisabled(res.data);
            })
            .catch(err => {
                setOperatorDisabled(false);
                console.log(err);
            })
        }
    },[currentDate])
    return showScheda ? 
        (
            <>
                <Schede open={showScheda} show={setShowScheda}/>
            </>
        )
        :
        (
        <>
           <div className="gradientground">
                <div className='d-flex justify-content-center align-item-center'>
                <Container style={{marginTop: '3rem', marginBottom: '5rem'}}>
                    <Row>
                        <Logo/>
                    </Row>
                    <Row className="wrapper">
                        <Col xs={12}>
                            <NavBar active={{path: '/orari'}} showScheda={setShowScheda}/>
                        </Col>
                        <Col xs={12}>
                            <Card style={{overflow: 'scroll', width: '100%', height: '100%'}}>
                                <Card.Body>
                                    <Card.Title style={{width: '100%', background: 'pink', padding: '1rem'}}>Orari</Card.Title>
                                    <Form.Select value={currentOperator} key="operator" style= {{marginTop: '1em'}} onChange={handleOperator}>
                                        <option disabled hidden value="0">Seleziona operatore...</option>
                                        {operatori.map((item) => {
                                            return (<option value={item.ID}>{item.Nome}</option>)
                                        })}                       
                                    </Form.Select>                                      
                                        <Form.Select value={currentDay} disabled={currentOperator != "0" ? false : true} key="dayofweek" style= {{marginTop: '1em'}} onChange={handleChangeDay}>
                                            <option disabled hidden value="-1">Seleziona giorno...</option>
                                            <option value="1">Lunedì</option>
                                            <option value="2">Martedì</option>
                                            <option value="3">Mercoledì</option>
                                            <option value="4">Giovedì</option>
                                            <option value="5">Venerdì</option>
                                            <option value="6">Sabato</option>
                                            <option value="0">Domenica</option>                        
                                        </Form.Select>
                                        <Form.Select value={currentDate} disabled={currentDay >= "0" ? false : true} key="dateofweek" style= {{marginTop: '1em'}} onChange={handleChangeDate}>
                                        <option hidden disabled selected value="0">Seleziona data...</option>
                                            {listadate.map((item) => {
                                                return (<option value={item}>{item}</option>)
                                        })}                                
                                        </Form.Select>
                                        {currentOperator != "0" && currentDate == "0" ?
                                        <h1>Ore Standard</h1> : (currentOperator != "0" && currentDate != "0" ?
                                        <h1>Ore Personalizzate <Button onClick={handleDisable} variant={operatordisabled ? 'danger' : 'success'}>{operatordisabled ? <span>Abilita giorno</span> : <span>Disabilita giorno</span>}</Button></h1>:  null)}
                                        {listapren?.length ? 
                                            <>
                                                {(() => {
                                                    const arr = []
                                                    {listapren.map((item) => {
                                                        return arr.push(<h6>{item.Nome} {item.Cognome} - {item.Data} {item.Ora} - {item.Servizi} {item.Note}</h6>);
                                                    })}
                                                    return arr;
                                                })()}
                                            </> : null
                                        }
                                        {currentOperator ? 
                                            (isLoaded ? 
                                            <Table>
                                                <tbody>
                                                    {(() => {
                                                        const arr = []
                                                        orari?.map((ore, index, elements) => {
                                                            const arr2 = []
                                                            listaore?.map((item) => {
                                                                if(item.RifOra >= ore && item.RifOra < elements[index+1]) {
                                                                    return arr2.push(<td><Button onClick={handleClick} disabled={operatordisabled} variant={item.Attivo ? 'success' : 'danger'}>{item.RifOra}</Button></td>)
                                                                }
                                                                return false;
                                                            })
                                                            return arr.push(<tr>{arr2}</tr>);
                                                        });
                                                        return arr;
                                                    })()}
                                                </tbody>
                                            </Table>
                                        : null) : <LoadSpinner/> }
                                </Card.Body>
                                {confirm ? <Confirm text={{title: 'Orario salvato', val: "L'orario è stato salvato con successo!"}}/> : null}
                            </Card>
                        </Col>
                    </Row>
                </Container>
                </div>
                <Footer/>
            </div>
       </>
    );
}


export default Orari;