import {React, useState, useEffect, useContext} from 'react';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import NavBar from '../Global/NavBar';
import Logo from '../Global/Logo';
import Footer from '../Footer/Footer';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/esm/Form';
import Button from 'react-bootstrap/esm/Button';
import { CheckLg, PencilSquare, XLg } from 'react-bootstrap-icons';
import { makeRequest } from '../Global/makeRequest';
import { uSocket } from '../Socket/Socket';
import { HandleLogout } from '../Global/Logout';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Auth/authContext';
import Schede from '../Schede/Schede';

function Dashboard() {
    const getMese = (mese) => {
        let val = '';
        switch(mese) {
            case '01': {
                val = 'Gennaio';
                break;
            }
            case '02': {
                val = 'Febbraio';
                break;
            }
            case '03': {
                val = 'Marzo';
                break;
            }
            case '04': {
                val = 'Aprile';
                break;
            }
            case '05': {
                val = 'Maggio';
                break;
            }
            case '06': {
                val = 'Giugno';
                break;
            }
            case '07': {
                val = 'Luglio';
                break;
            }
            case '08': {
                val = 'Agosto';
                break;
            }
            case '09': {
                val = 'Settembre';
                break;
            }
            case '10': {
                val = 'Ottobre';
                break;
            }
            case '11': {
                val = 'Novembre';
                break;
            }
            case '12': {
                val = 'Dicembre';
                break;
            }
            default: {
                val = "Errore";
                break;
            }
        }
        return val;
    }
    const getDay = (value) => {
        let val = "Errore";
        switch(value) {
            case 0: {
                val = "Domenica"
                break;
            }
            case 1: {
                val = "Lunedì"
                break;
            }
            case 2: {
                val = "Martedì"
                break;
            }
            case 3: {
                val = "Mercoledì"
                break;
            }
            case 4: {
                val = "Giovedì"
                break;
            }
            case 5: {
                val = "Venerdì"
                break;
            }
            case 6: {
                val = "Sabato"
                break;
            }
            default: {
                val = "Errore"
                break;
            }
        }
        return val;
    }
    const getData = (data) => {
        const split = data.split('-');
        const mese = getMese(split[1]);
        const newDate = new Date(split[2]+'-'+split[1]+'-'+split[0]);
        const dayofweek = getDay(newDate.getDay());
        const final = dayofweek+' '+split[0]+' '+mese+' '+split[2];
        return final;
    }
    const [nota, setNota] = useState("");
    const [error, showError] = useState(false);
    const [confirm, showConfirm] = useState(false);
    const [richiestePren, setRichiestePren] = useState([])
    const [peoplePrenota, setPeoplePren] = useState(0);
    const [peopleContatti, setPeopleCon] = useState(0);
    const [peopleDisdetta, setPeopleDisdetta] = useState(0);
    const [peopleOnline, setPeopleOnline] = useState(0);
    const [showScheda, setShowScheda] = useState(false);
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();
    const { logout } = useContext(AuthContext);
    useEffect(() => {
        uSocket?.on('peoplePrenota', (data) => {
            setPeoplePren(data.peoplePrenota);
        });
        uSocket?.on('peopleDisdetta', (data) => {
            setPeopleDisdetta(data.peopleDisdetta);
        });
        uSocket?.on('peopleContatti', (data) => {
            setPeopleCon(data.peopleContatti);
        });
        uSocket?.on('peopleOnline', (data) => {
            setPeopleOnline(data.countOnline);
        });
        uSocket?.on('peopleSend', () => {
            setReload(!reload);
        });
    }, [])
    const removePren = (id) => {
        let cellulare = '';
        let nome = '';
        let ora = '';
        let data = '';
        let to = '';
        const newArr = richiestePren.map((item) => {
            console.log(item.ID);
            if(item.ID == id) {
                nome = item.Nome;
                ora = item.Ora;
                data = item.Data;
                cellulare = item.Cellulare;
                to = item.Email;
                return {...item, Registrato: 1}
            }
            return item;
        });
        setRichiestePren(newArr);
        const stringdata = getData(data);
        const text = "Ciao "+nome+", la tua richiesta di prenotazione è stata accettata. Ti aspettiamo "+stringdata+" alle "+ora+". Qualora non fosse possibile rispettare l’appuntamento, ti preghiamo di annullarlo almeno 24 ore prima. Si fa presente che dopo 15 minuti di ritardo l'appuntamento verrà considerato nullo. Ti ricordiamo che potrai usufruire del parcheggio  convenzionato Sperlinga parking in via Cesareo 16, a meno di 20m dal salone. Basterà portare con se il biglietto che ti verrà consegnato: verrà timbrato con l’orario d’uscita e riconsegnato al parcheggio.";
        const link = "whatsapp://send?phone=39"+cellulare+"&abid=39"+cellulare+'&text='+text;
        window.location.href = link;
        setTimeout(() => {
            window.location.href = link;
        },3000);
        const subject = "Prenotazione Accettata";
        makeRequest.post('/pren/sendmail/', {to, subject, text, text})
        return newArr;
    }
    const deletePren = (id) => {
        let nome = ''
        let cellulare = '';
        let to = '';
        richiestePren.map((item) => {
            if(item.ID == id) {
                to = item.Email;
                nome = item.Nome;
                cellulare = item.Cellulare;
            }
            return item;
        });
        setRichiestePren(richiestePren.filter((item) => item.ID != id));
        const text = "Ciao "+nome+", la tua richiesta di prenotazione è stata rifiutata. Ti invitiamo a prenotare nuovamente un nuovo appuntamento sul nostro sito https://beautyhairpalermo.it Grazie e a presto!";
        const link = "whatsapp://send?phone=39"+cellulare+"&abid=39"+cellulare+'&text='+text;
        const subject = "Prenotazione Rifiutata"
        makeRequest.post('/pren/sendmail/', {to, subject, text, text})
        window.location.href = link;
        setTimeout(() => {
            window.location.href = link;
        },3000);
    }
    const handleNota = () => {
        if(nota.length > 0) {
            makeRequest.put('/note/add/', {nota})
            .then(res=>{
                setNota('');
                showError(false);
                showConfirm(true);
                setTimeout(() => {
                    showConfirm(false);
                },3000)
            })
            .catch(err => console.log(err))
        } else {
            showError(true);
            showConfirm(false);
            setTimeout(() => {
                showError(false);
            },3000)
        }
    }
    const handleAccept = (e) => {
        const id = e.currentTarget.id;
        makeRequest.put("/pren/accept/",{id})
        .then(async (res) => {
            if(res.data.affectedRows > 0) {
                removePren(id)
            }
        })
        .catch((err) =>  {
            console.log(err)
        })
    }
    const handleRemove = (e) => {
        const id = e.currentTarget.id;
        makeRequest.post("/pren/remove/",{id})
        .then(async (res) => {
            if(res.data.affectedRows > 0) {
                deletePren(id)
            }
        })
        .catch((err) =>  {
            console.log(err)
        })
    }
    useEffect(() => {
        const getRichiestePren = async() => {
            makeRequest.get("/pren/get/")
            .then((res) => {
                if(res.data?.length > 0 )
                    setRichiestePren(res.data);
            })
            .catch((err) => {
                if(err.response?.status === 403)
                    HandleLogout(logout,navigate);
            });
        }
        getRichiestePren();
    }, [reload])
    return showScheda ? (
        <>
            <Schede open={showScheda} show={setShowScheda}/>
        </>
        ) :
        (
        <>
            <div className="gradientground">
                <div className='d-flex justify-content-center align-item-center'>
                <Container style={{marginTop: '3rem', marginBottom: '5rem'}}>
                    <Row>
                        <Logo/>
                    </Row>
                    <Row className="wrapper">
                        <Col xs={12}>
                            <NavBar active={{path: '/'}} showScheda={setShowScheda}/>
                        </Col>
                        <Col xs={12} md={8}>
                            <Card style={{overflow: 'scroll', width: '100%', minHeight: '50vh', height: '80%'}}>
                                <Card.Body>
                                    <Card.Title style={{overflow: 'scroll', display: 'inline-block', width: '100%', background: 'pink', padding: '0.5rem'}}>Richieste</Card.Title>
                                        <Table striped bordered variant="light">
                                            <thead>
                                                <tr style={{background:'#fff', zIndex: '900'}} className="sticky-top thPren">
                                                    <th style={{padding: '1rem'}}>#</th>
                                                    <th>Nome</th>
                                                    <th>Cognome</th>
                                                    <th>Data</th>
                                                    <th>Cellulare</th>
                                                    <th>Servizi</th>
                                                    <th>Note</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {(() => {
                                                //Map after useEffect to retrieve activities
                                                const fin = []
                                                richiestePren.map((item, index) => {
                                                    if(!item.Registrato) 
                                                        return(fin.push(<tr key={index}>
                                                            <td style={{padding: '1rem'}}>{index+1}</td>
                                                            <td>{item.Nome}</td>
                                                            <td>{item.Cognome}</td>
                                                            <td>{item.Data} {item.Ora}</td>
                                                            <td>{item.Cellulare}</td>
                                                            <td>{item.Servizi}</td>
                                                            <td>{item.Note}</td>
                                                            <td><Button id={item.ID} onClick={handleAccept} variant="light"><CheckLg style={{color: 'green'}}/></Button></td>
                                                            <td><Button id={item.ID} onClick={handleRemove} variant="light"><XLg style={{color: 'red'}}/></Button></td>
                                                        </tr>));
                                                    return false;
                                                });
                                                return fin;
                                            })()}
                                            </tbody>
                                        </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={4}>
                            <Card style={{overflow: 'scroll', width: '100%', height: '80%'}}>
                                <Card.Body>
                                    <Card.Title style={{width: '100%', background: 'pink', padding: '0.5rem'}}className="sticky-top">Nota Rapida</Card.Title>
                                        <Form.Control as="textarea" rows={3}
                                            onChange={(e) => setNota(e.target.value)}
                                            value={nota}
                                            key="notarapida"
                                            style= {{marginTop: '1em'}}
                                            id="note"
                                            placeholder="Scrivi la tua nota..."
                                        />
                                        <Button disabled={(!confirm && !error ? false : true)} onClick={handleNota} style={{position: 'relative', bottom: '0px', marginTop: '1rem'}} variant="light">{(!confirm  && !error ? <span><PencilSquare/> Salva</span> : (confirm ? <CheckLg style={{fontSize: '1.5em', color: 'green'}}/> : <XLg style={{fontSize: '1.5em', color: 'red'}}/>))}</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card style={{overflowX: 'scroll', width: '100%', height: '80%'}}>
                                <Card.Body>
                                    <Card.Title style={{background: 'pink', padding: '0.5rem'}} className="sticky-top">Attività recenti</Card.Title>
                                        <Table striped bordered variant="light">
                                            <tbody>
                                            {(() => {
                                                //Map after useEffect to retrieve activities
                                                const fin = []
                                                for(let i = 0; i < 10; i++) 
                                                    fin.push(<tr key={i}><td>{i}</td></tr>);
                                                return fin;
                                            })()}
                                            </tbody>
                                        </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card style={{overflowX: 'scroll', width: '100%', height: '80%'}}>
                                <Card.Body>
                                    <Card.Title style={{background: 'pink', padding: '0.5rem'}} className="sticky-top">Persone collegate</Card.Title>
                                        <p><span style={{color: 'red'}}><b>{peopleOnline}</b></span> persone collegate al sito web</p>
                                        <p><span style={{color: 'red'}}><b>{peoplePrenota}</b></span> persone stanno prenotando un appuntamento</p>
                                        <p><span style={{color: 'red'}}><b>{peopleDisdetta}</b></span> persone stanno disdicendo un appuntamento</p>
                                        <p><span style={{color: 'red'}}><b>{peopleContatti}</b></span> persone stanno visualizzando i contatti</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer/>
        </div>
        </>
    );
}


export default Dashboard;