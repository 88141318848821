import {React, useState, useEffect} from 'react';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import NavBar from '../Global/NavBar';
import Logo from '../Global/Logo';
import Footer from '../Footer/Footer';
import Button from 'react-bootstrap/esm/Button';
import { makeRequest } from '../Global/makeRequest';
import Card from 'react-bootstrap/esm/Card'
import Form from 'react-bootstrap/esm/Form';
import Confirm from '../Global/Confirm';
import Schede from '../Schede/Schede';
import { uSocket } from '../Socket/Socket';
function Sconti() {
    const [sconto, setMessage] = useState("");
    const [confirm, showConfirm] = useState(false);
    const [showScheda, setShowScheda] = useState(false);
    const handleSave = () => {
        const messaggio = sconto?.Messaggio
        makeRequest.put("/sconto/save/", {messaggio})
        .then(res => {
            showConfirm(true);
            setTimeout(()=>{
                showConfirm(false);
            },3000)
        })
        .catch(err => console.log(err))
    }
    useEffect(() => {
        makeRequest.get("/sconto/get/")
        .then(res => { console.log(res.data); setMessage(res.data[0])})
        .catch(err => console.log(err))
    },[])
    return showScheda ? 
        (
            <>
                <Schede open={showScheda} show={setShowScheda}/>
            </>
        )
        :
        (
        <>
        <div className="gradientground">
            <div className='d-flex justify-content-center align-item-center'>
                <Container style={{marginTop: '3rem', marginBottom: '5rem'}}>
                    <Row>
                        <Logo/>
                    </Row>
                    <Row className="wrapper">
                        <Col xs={12}>
                            <NavBar active={{path: '/sconti'}} showScheda={setShowScheda}/>
                        </Col>
                        <Col xs={12}>
                            <Card>
                                <Card.Title style={{background: 'pink', width: '100%', padding: '1rem'}}>Messaggio Promozionale</Card.Title>
                                <Card.Body>
                                    {confirm ? <Confirm text={{title: 'Messaggio Promozionale salvato', val: 'Il messaggio promozionale è stato salvato con successo!'}}/> : null}
                                    <Form.Control as="textarea" rows={10}
                                        placeholder="Messaggio..."
                                        value={sconto?.Messaggio}
                                        onChange={(e) => { const value = e.currentTarget.value; setMessage({...sconto, Messaggio: value})}}
                                    >
                                    </Form.Control>
                                    <Button style={{marginTop: '1rem'}} variant="light" onClick={handleSave}>Salva</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer/>
        </div>
        </>
    );
}
export default Sconti;