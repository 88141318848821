import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Image from 'react-bootstrap/esm/Image';

function Logo() {
    return (
        <Col style={{marginBottom: '3rem', background: 'inherit', borderRadius: '3px'}} className="text-left" xs={6} sm={6} md={6} lg={3}>
            <Image
                style={{marginLeft: '1rem'}}
                src="./beautyhairbanner.png"
                width="200"
                height="100"
                rounded
                fluid
                alt="Hair Beauty di Giada Messina a Palermo Logo"
            />
        </Col>
    );
}


export default Logo;